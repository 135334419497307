const config = {
  platforms: {
    SA: {
      UI_URL: "https://sa.reiformslive.com.au",
      API_URL: "https://sa.api.reiformslive.com.au",
      declineEnabled: true,
    },
    FLSA: {
      UI_URL: "https://sa.formslive.com.au",
      API_URL: "https://sa.api.formslive.com.au",
      declineEnabled: true,
      checklistEnabled: true,

      theme: {
        icon: "fl-icon-inverted.png",
        logo: "fl-logo.png",
        "Theme-Color-Primary": "#4884ee",
        "Theme-Button-Primary": "#4884ee",
      },
    },
    NT: {
      UI_URL: "https://nt.reiformslive.com.au",
      API_URL: "https://nt.api.reiformslive.com.au",
      declineEnabled: true,
    },
    TAS: {
      checklistEnabled: true,
      UI_URL: "https://tas.reiformslive.com.au",
      API_URL: "https://tas.api.reiformslive.com.au",
    },
    VIC: {
      checklistEnabled: true,
      UI_URL: "https://vic.formslive.com.au",
      API_URL: "https://vic.api.formslive.com.au",
      theme: {
        icon: "fl-icon-inverted.png",
        logo: "fl-logo.png",
        "Theme-Color-Primary": "#4884ee",
        "Theme-Button-Primary": "#4884ee",
      },
    },
  },
  livesign: {
    scriptId: "__livesign_widget",
    url: "https://widget.verify.live-sign.com",
  },
};

export default config;
